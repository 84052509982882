<template>
  <div id="app">
    <header>
      <h1>Under Par Club</h1>
    </header>
    <main>
      <p>Coming Soon</p>
    </main>
  </div>
</template>

<script>
export default {
  name: 'App',
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

h1 {
  font-size: 2em;
}

p {
  font-size: 1.5em;
  margin-top: 20px;
}
</style>